<template>
    <div>
        <!--begin::Card-->
        <div class="card card-custom">
            <!--begin::Header-->
            <div class="card-header border-0 py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">
                        {{ $t("PRODUCT.QRCODES") }}
                        {{qrcodes.length}}
                    </h3>
                     <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
                                                   $tc("PRODUCT.SHOW_COUNT", qrcodes.length, {
                                                     total: qrcodes.length,
                                                   })
                                                 }}</span>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-0">

   <!--begin::Table-->
                <div class="table-responsive" v-if="qrcodes.length">

                    <table
                            class="table table-head-custom table-vertical-center"
                            id="kt_advance_table_widget_4"
                    >
                        <thead>
                        <tr class="text-left">
                            <th class="pl-0" style="min-width: 120px">


                               <a
                                                    v-on:click="showme(1)"

                                                  >

                                                  {{ $t("PRODUCT.QRCODE_CODE") }}
                                                           </a>
                               <a v-if="this.sorti==0"
                                                    v-on:click="showme(0)"

                                                  >

                                                  {{ $t("PRODUCT.QRCODE_CODE") }}
                                                           </a>

                            </th>
                            <th class="pl-0" style="min-width: 120px">
                                {{ $t("PRODUCT.QRCODE_IXCODE") }}
                            </th>


                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="qrcodes in paginatedqrcodes">
                            <tr v-bind:key="qrcodes.code">
                                <td>
                                   <span class="text-dark-75 font-weight-bold d-block font-size-lg">{{ qrcodes.code }}</span>
                                </td>
                                 <td>
                                    <span class="text-dark-75 font-weight-bold d-block font-size-lg">{{ qrcodes.indexed_code }}</span>
                                 </td>

                            </tr>
                        </template>
                        </tbody>
                    </table>

                </div>
                <!--end::Table-->

</div>



            <!--end::Body-->
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import Swal from "sweetalert2";

    export default {
        name: "qrcodes",
       sorti:0,
        data() {
            return {
                qrcodes: [],
                alert: {
                    show: false,
                    message: "",
                    variant: "",
                },
                pagination: {
                    current_page: 1,
                    per_page: 5 ,
                },
            };
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, []);

            ApiService.get("qrcodes/" + this.$route.params.id)
                .then(({data}) => {
                    this.qrcodes = data;

                })
                .catch(() => {
                    //Just keep it empty
                });
        },
        methods: {
            showme: function(sort) {
            alert(sort);
              this.$route.params.sorting = sort;
               this.sorti = sort;
               ApiService.get("qrcodes/" + this.$route.params.id)
                            .then(({data}) => {
                                this.qrcodes = data;

                            })
                            .catch(() => {
                                //Just keep it empty
                            });



            },
            confirmDelete: function (id) {
                const product_index = this.products.findIndex(
                    (product) => product.id === id
                );
                const product = this.products.find((product) => product.id === id);
                Swal.fire({
                    title: this.$t("PRODUCT.DELETE_TITLE", {name: product.name}),
                    text: this.$t("PRODUCT.DELETE_TEXT"),
                    icon: "error",
                    showCancelButton: true,
                    cancelButtonText: this.$t("CANCEL"),
                    confirmButtonText: this.$t("DELETE"),
                    buttonsStyling: false,
                    customClass: {
                        cancelButton: "btn btn-secondary",
                        confirmButton: "btn btn-danger",
                    },
                    heightAuto: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        ApiService.delete("product/" + id)
                            .then(() => {
                                this.products.splice(product_index, 1);
                                this.showAlert(
                                    this.$t("PRODUCT.DELETE_SUCCESS", {name: product.name}),
                                    "success"
                                );
                            })
                            .catch(() => {
                                this.showAlert(
                                    this.$t("PRODUCT.DELETE_FAILURE", {name: product.name}),
                                    "danger"
                                );
                            });
                    }
                });
            },
            showAlert(text, variant) {
                window.scrollTo(0, 0);
                this.alert.text = text;
                this.alert.variant = variant;
                this.alert.show = true;
                setTimeout(() => (this.alert.show = false), 5000);
            },
        },
        computed: {
            paginatedqrcodes() {
                const start =
                    (this.pagination.current_page - 1) * this.pagination.per_page,
                    end = start + this.pagination.per_page;
                return this.qrcodes.slice(start, end);
            },
        },
    };
</script>



